<template>
  <div>
    <profile-tile
      :title="$t('t.LegalNotices')"
      :text="$t('t.LegalNotices')"
      :icon="$icon('i.Document')"
      @click.native="$router.push({name: 'mobility-cgu'})"
    />
    <profile-tile
      :title="$t('t.Theme')"
      :text="$t('t.CustomizeYourTheme')"
      :icon="$icon('i.Eye')"
      @click.native="$router.push({name: 'mobility-theme'})"
    />
    <profile-tile
      emphasis
      :title="$t('t.SignOut')"
      :text="$t('t.SignOutDetail')"
      :icon="$icon('i.Logout')"
      @click.native="showLogoutConfirmation = true"
    />
    <v-dialog
      v-model="showLogoutConfirmation"
      :overlay-color="themeController.isDark?'#f2f2f2':undefined"
    >
      <v-card>
        <v-card-title>
          {{$t('t.SignOut')}}
        </v-card-title>
        <v-card-text>
          {{$t('t.SignOutDetail')}}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="showLogoutConfirmation = false"
          >
            {{$t('t.No')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="logout()"
          >
            {{$t('t.Yes')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import themeController from '@/themeController'

export default {
  components: {
    ProfileTile: () => import('@/pages/mobility/components/profile-tile')
  },
  activated () {
    this.setModule()
  },
  created () {
    this.setModule()
  },
  data () {
    return {
      showLogoutConfirmation: false,
      themeController
    }
  },
  methods: {
    logout () {
      this.showLogoutConfirmation = false
      this.$http().logout()
      this.themeController.resetUiSettings()
      this.$router.push({ name: 'mobility-login' })
    },
    setModule () {
      this.$store.commit('setModule', { name: this.$t('t.MyProfile') })
    }
  }
}
</script>
